import Phaser from 'phaser';

export default class CookGameScene extends Phaser.Scene {
  constructor() {
    super('CookGameScene');
    this.score = 0;
    this.lives = 3;
    this.speedMultiplier = 1;
    this.gameOver = false;
    this.nickname = '';
    this.chefSpeed = 10;
    this.speedBoostCount = 0;
    this.maxSpeedBoosts = 5;
    this.isPaused = false;
  }

  preload() {
    this.load.image('chef', '/assets/chef.png');
    this.load.image('burger', '/assets/burger.png');
    this.load.image('hotdog', '/assets/hotdog.png');
    this.load.image('mine', '/assets/mine.png');
    this.load.image('heart', '/assets/heart.png');
    this.load.image('golden-burger', '/assets/golden-burger.png');
    this.load.image('red-mine', '/assets/red-mine.png');
    this.load.image('booster', '/assets/booster.png');
    this.load.image('background', '/assets/background.png');
    this.load.image('border', '/assets/border.png');
    this.load.audio('bg-music', '/assets/background-music.mp3');
    this.load.audio('plop', '/assets/plop.mp3');
    this.load.audio('boom', '/assets/boom.mp3');
    this.load.audio('heart-sound', '/assets/heart.mp3');
    this.load.audio('golden-burger', '/assets/golden-burger.mp3');
    this.load.audio('red-mine', '/assets/red-mine.mp3');
    this.load.audio('booster-sound', '/assets/booster.mp3');
    this.load.audio('cooked', '/assets/cooked.mp3');
  }

  create() {
    this.nickname = localStorage.getItem('nickname') || '';

    this.bgMusic = this.sound.add('bg-music');
    this.bgMusic.play({ loop: true });

    this.add
      .image(0, 0, 'background')
      .setOrigin(0, 0)
      .setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    this.physics.world.setBounds(-Infinity, -Infinity, Infinity, Infinity);
    this.cameras.main.setBounds(0, 0, this.cameras.main.width, this.cameras.main.height);

    // Percentage-based chef position
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    const chefX = gameWidth * 0.5; // 50% of width (center)
    const chefY = gameHeight * 0.83; // 83% of height (near bottom, adjusted from 500/600 ≈ 0.833)
    this.chef = this.physics.add.sprite(chefX, chefY, 'chef').setScale(0.25);
    this.chef.targetXPercent = 0.5; // Store for resize
    this.chef.targetYPercent = 0.83;
    this.cameras.main.startFollow(this.chef, true, 0.05, 0.05);

    this.cursors = this.input.keyboard.createCursorKeys();

    // Percentage-based movement borders
    this.physics.world.on('worldstep', () => {
      const leftBorder = gameWidth * 0.15; // 15% ≈ 150px on 1000px width
      const rightBorder = gameWidth * 0.85; // 85% ≈ 850px on 1000px width
      if (this.chef.x < leftBorder) {
        this.chef.x = leftBorder;
      }
      if (this.chef.x > rightBorder) {
        this.chef.x = rightBorder;
      }
    });

    const floor = this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height - 20,
      this.cameras.main.width,
      119,
      0x000000
    );
    this.physics.add.existing(floor, true);
    this.physics.add.collider(this.chef, floor);

    this.add
      .text(
        this.cameras.main.width / 2,
        this.cameras.main.height - 20,
        '@LetHimCookCoin G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n',
        {
          fontSize: '32px',
          color: '#ffffff',
          fontFamily: 'Arial',
          stroke: '#000',
          strokeThickness: 5,
        }
      )
      .setOrigin(0.5);

    const leftBorder = this.add
      .image(0, this.cameras.main.height / 2, 'border')
      .setOrigin(0, 0.5)
      .setDisplaySize(100, this.cameras.main.height)
      .setTint(0xffffff)
      .setAlpha(0.8);

    const rightBorder = this.add
      .image(this.cameras.main.width - 100, this.cameras.main.height / 2, 'border')
      .setOrigin(0, 0.5)
      .setDisplaySize(100, this.cameras.main.height)
      .setTint(0xffffff)
      .setAlpha(0.8);

    this.tweens.add({
      targets: [leftBorder, rightBorder],
      alpha: { from: 0.8, to: 1 },
      ease: 'Sine.easeInOut',
      duration: 1000,
      repeat: -1,
      yoyo: true,
    });

    this.soundButton = this.add
      .text(55, this.cameras.main.height / 2, 'Mute', {
        fontSize: '20px',
        fill: '#ffffff',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive()
      .setDepth(10);

    this.soundButton.on('pointerdown', () => {
      if (this.bgMusic.isPlaying) {
        this.sound.stopAll();
        this.soundButton.setText('Music');
      } else {
        this.bgMusic.play({ loop: true });
        this.soundButton.setText('Mute');
      }
    });

    // Percentage-based collector position
    this.collector = this.physics.add.sprite(chefX, chefY - gameHeight * 0.03, null).setVisible(false); // ~20px on 600px height
    this.collector.body.setSize(50, 20);
    this.collector.body.immovable = true;

    this.foodGroup = this.physics.add.group();
    this.mineGroup = this.physics.add.group();
    this.boosterGroup = this.physics.add.group();
    this.goldenBurgerGroup = this.physics.add.group();
    this.redMineGroup = this.physics.add.group();
    this.speedBoosterGroup = this.physics.add.group();

    this.scoreText = this.add.text(20, 10, `$cook points: ${this.score}`, {
      fontSize: '24px',
      fill: '#ff6347',
      fontFamily: 'Arial',
      stroke: '#000',
      strokeThickness: 3,
      backgroundColor: '#222',
      padding: { x: 10, y: 5 },
    });

    this.livesText = this.add
      .text(this.cameras.main.width - 30, 10, 'Plates left: 3', {
        fontSize: '24px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setOrigin(1, 0);

    this.pauseButton = this.add
      .text(10, this.cameras.main.height - 40, 'Pause', {
        fontSize: '24px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setInteractive()
      .setDepth(10)
      .on('pointerdown', () => this.togglePause());

    this.pauseOverlay = this.add
      .rectangle(this.cameras.main.width / 2, this.cameras.main.height / 2, this.cameras.main.width, this.cameras.main.height, 0x000000, 0.8)
      .setDepth(10)
      .setVisible(false);

    this.pauseText = this.add
      .text(this.cameras.main.width / 2, this.cameras.main.height / 2 - 50, 'Game Temporarily Cooked', {
        fontSize: '30px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setDepth(11)
      .setVisible(false);

    this.resumeButton = this.createInteractiveButton(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 20,
      'Resume Cooking',
      () => this.togglePause()
    )
      .setOrigin(0.5)
      .setDepth(11)
      .setVisible(false);

    this.restartButton = this.createInteractiveButton(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 70,
      'Restart Cooking',
      () => this.restartGame()
    )
      .setOrigin(0.5)
      .setDepth(11)
      .setVisible(false);

    this.returnButton = this.createInteractiveButton(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 120,
      'Stop Cooking and Return to Menu',
      () => this.returnToMenu()
    )
      .setOrigin(0.5)
      .setDepth(11)
      .setVisible(false);

    this.spawnObjects();
    this.spawnSpeedBooster();

    this.scale.on('resize', this.resizeUI, this);
  }

  resizeUI(gameSize) {
    try {
      const width = gameSize.width;
      const height = gameSize.height;

      this.scoreText.setPosition(20, 10);
      this.livesText.setPosition(width - 20, 10);
      this.pauseButton.setPosition(10, height - 40);

      this.pauseOverlay.setSize(width, height);
      this.pauseOverlay.setPosition(width / 2, height / 2);
      this.pauseText.setPosition(width / 2, height / 2 - 50);
      this.resumeButton.setPosition(width / 2, height / 2 + 20);
      this.returnButton.setPosition(width / 2, height / 2 + 120);
      this.restartButton.setPosition(width / 2, height / 2 + 70);

      // Reposition chef and collector on resize
      this.chef.setPosition(width * this.chef.targetXPercent, height * this.chef.targetYPercent);
    } catch (error) {
      console.warn('Resize error suppressed:', error);
    }
  }

  togglePause() {
    if (this.isPaused) {
      this.isPaused = false;
      this.physics.resume();
      this.pauseOverlay.setVisible(false);
      this.pauseText.setVisible(false);
      this.resumeButton.setVisible(false);
      this.returnButton.setVisible(false);
      this.restartButton.setVisible(false);
    } else {
      this.isPaused = true;
      this.physics.pause();
      this.pauseOverlay.setVisible(true);
      this.pauseText.setVisible(true);
      this.resumeButton.setVisible(true);
      this.returnButton.setVisible(true);
      this.restartButton.setVisible(true);
    }
  }

  returnToMenu() {
    this.resetGame();
    this.scene.stop();
    this.scene.start('MainMenuScene');
    this.sound.stopAll();
    this.isPaused = false;
  }

  spawnObjects() {
    if (this.gameOver) return;

    this.time.addEvent({
      delay: 500 / this.speedMultiplier,
      callback: () => {
        if (this.isPaused) return;

        // Percentage-based spawn range
        const gameWidth = this.game.config.width;
        const x = Phaser.Math.Between(gameWidth * 0.15, gameWidth * 0.85);
        const randomChance = Phaser.Math.Between(1, 100);
        let type;

        if (randomChance <= 60) {
          type = Phaser.Math.RND.pick(['burger', 'hotdog']);
        } else if (randomChance <= 80) {
          type = 'mine';
        } else if (randomChance <= 90) {
          type = 'heart';
        } else if (randomChance <= 95) {
          type = 'golden-burger';
        } else {
          type = 'red-mine';
        }

        const y = -50;
        this[
          type === 'mine'
            ? 'mineGroup'
            : type === 'heart'
            ? 'boosterGroup'
            : type === 'golden-burger'
            ? 'goldenBurgerGroup'
            : type === 'red-mine'
            ? 'redMineGroup'
            : 'foodGroup'
        ]
          .create(x, y, type)
          .setScale(0.2)
          .setVelocityY(200 * this.speedMultiplier);

        this.speedMultiplier += 0.02;
      },
      loop: true,
    });

    this.physics.add.overlap(this.collector, this.foodGroup, this.catchFood, null, this);
    this.physics.add.overlap(this.collector, this.mineGroup, this.hitMine, null, this);
    this.physics.add.overlap(this.collector, this.boosterGroup, this.collectBooster, null, this);
    this.physics.add.overlap(this.collector, this.goldenBurgerGroup, this.collectGoldenBurger, null, this);
    this.physics.add.overlap(this.collector, this.redMineGroup, this.hitRedMine, null, this);
    this.physics.add.overlap(this.collector, this.speedBoosterGroup, this.collectSpeedBooster, null, this);
  }

  spawnSpeedBooster() {
    if (this.gameOver) return;

    this.time.addEvent({
      delay: 10000,
      callback: () => {
        if (this.isPaused || this.speedBoostCount >= this.maxSpeedBoosts) return;

        // Percentage-based spawn range
        const gameWidth = this.game.config.width;
        const x = Phaser.Math.Between(gameWidth * 0.15, gameWidth * 0.85);
        const y = -50;

        this.speedBoosterGroup.create(x, y, 'booster').setScale(0.2).setVelocityY(200 * this.speedMultiplier);
      },
      loop: true,
    });
  }

  collectSpeedBooster(collector, speedBooster) {
    if (this.speedBoostCount >= this.maxSpeedBoosts || this.gameOver) {
      speedBooster.destroy();
      return;
    }

    this.chefSpeed += 1;
    this.speedBoostCount += 1;
    speedBooster.destroy();
    this.sound.play('booster-sound');
    console.log(`Speed Boost collected! Current speed: ${this.chefSpeed}`);
  }

  collectBooster(collector, booster) {
    if (this.gameOver) return;

    if (this.lives < 3) {
      this.lives += 1;
      this.livesText.setText(`Plates left: ${this.lives}`);
      this.sound.play('heart-sound');
      booster.destroy();
    }
  }

  collectGoldenBurger(collector, goldenBurger) {
    if (this.gameOver) return;
    goldenBurger.destroy();
    this.score += 5;
    this.scoreText.setText(`$cook points: ${this.score}`);
    this.sound.play('golden-burger', { volume: 5 });
  }

  hitRedMine(collector, redMine) {
    if (this.gameOver) return;
    redMine.destroy();
    this.sound.play('red-mine');
    this.endGame();
  }

  hitMine(collector, mine) {
    if (this.gameOver) return;
    mine.destroy();
    this.sound.play('boom');
    this.lives -= 1;
    this.livesText.setText(`Plates left: ${this.lives}`);

    if (this.lives <= 0) {
      this.endGame();
    }
  }

  catchFood(collector, food) {
    if (this.gameOver) return;
    food.destroy();
    this.score += 1;
    this.scoreText.setText(`$cook points: ${this.score}`);
    this.sound.play('plop');
  }

  update() {
    if (this.gameOver || this.isPaused) return;

    if (this.cursors.left.isDown) {
      this.chef.x -= this.chefSpeed;
    } else if (this.cursors.right.isDown) {
      this.chef.x += this.chefSpeed;
    }

    // Update collector position to follow chef
    const gameHeight = this.game.config.height;
    this.collector.setPosition(this.chef.x, this.chef.y - gameHeight * 0.03);
  }

  endGame() {
    if (this.gameOver) return;
    this.gameOver = true;

    this.foodGroup.setVelocityY(0);
    this.mineGroup.setVelocityY(0);
    this.foodGroup.clear(true, true);
    this.mineGroup.clear(true, true);

    this.bgMusic.stop();
    this.sound.play('cooked');

    this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000,
      0.9
    ).setDepth(5);

    this.add
      .text(this.cameras.main.width / 2, this.cameras.main.height / 2 - 100, 'You are cooked bro.', {
        fontSize: '30px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setDepth(6);

    (async () => {
      try {
        const response = await fetch('/api/leaderboard', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            nickname: this.nickname,
            score: this.score,
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to save score: ${response.statusText}`);
        }

        const countResponse = await fetch('/api/player-count');
        if (!countResponse.ok) {
          throw new Error(`Failed to fetch player count: ${countResponse.statusText}`);
        }

        const countData = await countResponse.json();
        console.log(`Total players updated: ${countData.totalPlayers}`);
        localStorage.setItem('totalPlayers', countData.totalPlayers);
      } catch (error) {
        console.error('Error updating player count:', error);
      }
    })();

    this.createInteractiveButton(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      'Restart Cooking',
      () => this.restartGame()
    );

    this.createInteractiveButton(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2 + 50,
      'Stop Cooking and Return to Menu',
      () => this.returnToMenu()
    );
  }

  createInteractiveButton(x, y, text, onClick) {
    const button = this.add
      .text(x, y, text, {
        fontSize: '24px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: 3,
        backgroundColor: '#222',
        padding: { x: 10, y: 5 },
      })
      .setOrigin(0.5)
      .setInteractive()
      .setDepth(10);

    button.on('pointerover', () => {
      button.setStyle({ backgroundColor: '#444', color: '#ffffff' });
    });

    button.on('pointerout', () => {
      button.setStyle({ backgroundColor: '#222', color: '#ff6347' });
    });

    button.on('pointerdown', () => {
      button.setStyle({ backgroundColor: '#ff0000', color: '#ffffff' });
      onClick();
    });

    return button;
  }

  restartGame() {
    this.resetGame();
    this.scene.restart();
  }

  resetGame() {
    if (this.bgMusic) {
      this.bgMusic.stop();
    }
    this.score = 0;
    this.lives = 3;
    this.speedMultiplier = 1;
    this.chefSpeed = 10;
    this.speedBoostCount = 0;
    this.gameOver = false;
    this.isPaused = false;
    this.physics.world.resume();
  }
}