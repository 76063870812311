import React, { useEffect } from 'react';
import Phaser from 'phaser';
import MainMenuScene from './cookgame/scenes/MainMenuScene';
import CookGameScene from './cookgame/scenes/CookGameScene';
import mobilegamescene from './cookgame/scenes/mobilegamescene';

function GamePage() {
  useEffect(() => {
    // Phaser game configuration
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      parent: 'phaser-game-container',
      scene: [MainMenuScene, CookGameScene,mobilegamescene],
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
          debug: false,
        },
      },
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    const game = new Phaser.Game(config);

    // Resize handler to make sure the game resizes properly with the window
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (game && game.scale && game.canvas) {
          // Resize the game window
          game.scale.resize(window.innerWidth, window.innerHeight);

          // Explicitly refresh the scale system
          game.scale.refresh();

          // Adjust canvas size if it exists
          game.canvas.style.width = `${window.innerWidth}px`;
          game.canvas.style.height = `${window.innerHeight}px`;
        }

        // Adjust container size
        const container = document.getElementById('phaser-game-container');
        if (container) {
          container.style.width = '100%';
          container.style.height = '100%';
        }
      }, 100); // Debounce delay
    };

    window.addEventListener('resize', handleResize);

    // Stop intro and background video when entering the game scene
    const introVideo = document.getElementById('introVideo');
    const backgroundVideo = document.getElementById('backgroundVideo');

    if (introVideo) {
      introVideo.pause();
    }
    if (backgroundVideo) {
      backgroundVideo.pause();
    }

    // Cleanup when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      game.destroy(true);
    };
  }, []);

  return (
    <div>
      <div id="phaser-game-container" style={{ width: '100%', height: '100%' }} />
      {/* Removed Back to Main button as per your request */}
    </div>
  );
}

export default GamePage;
