import Phaser from 'phaser';

export default class MainMenuScene extends Phaser.Scene {
  constructor() {
    super({ key: 'MainMenuScene' });
  }

  preload() {
    // Preload any assets for the menu
    this.load.image('background', '/assets/background.png'); // Replace with your background
    this.load.image('button', '/assets/button.png'); // Replace with your button
    this.load.audio('menumusic', '/assets/menumusic.mp3');
  }

  create() {
    
    this.bgMusic = this.sound.add('menumusic');
    this.bgMusic.play({ loop: true });
   
    this.isSceneReady = true; // ✅ Set when all UI elements are ready
    // Sound On/Off Button in the left border
   this.soundButton = this.add.text(55, this.cameras.main.height / 2, 'Mute', {
    fontSize: '20px',
    fill: '#ffffff',
    fontFamily: 'Arial',
    stroke: '#000',
    strokeThickness: 3,
    backgroundColor: '#222',
    padding: { x: 10, y: 5 },
})
  .setOrigin(0.5)
  .setInteractive()
  .setDepth(10);  // Ensure the button is in front of other objects

  this.soundButton.on('pointerdown', () => {
    if (this.bgMusic.isPlaying) {
      this.sound.stopAll(); // Stop all sounds
      this.soundButton.setText('Music'); // Update button text
    } else {
      this.bgMusic.play({ loop: true }); // Resume the music
      this.soundButton.setText('Mute'); // Update button text
    }
  });
    
    // Dynamically scaled background
    this.bg = this.add.image(0, 0, 'background')
      .setOrigin(0, 0)
      .setDisplaySize(this.cameras.main.width, this.cameras.main.height);  

    // Title
    this.title = this.add.text(this.cameras.main.width / 2, 100, 'Let Him Cook', {
      fontSize: '48px',
      color: '#ff6347',
      fontFamily: 'Arial',
      stroke: '#000',
      strokeThickness: 3,
      backgroundColor: '#222',
      padding: { x: 5, y: 5 },
    }).setOrigin(0.5);

    // Nickname logic
    const savedNickname = localStorage.getItem('nickname');
    let nickname = savedNickname || 'Chef';

    this.nicknameText = this.add.text(this.cameras.main.width / 2, 200, `Chef ID: ${nickname}`, {
      fontSize: '24px',
      color: '#fff',
      fontFamily: 'Arial',
      stroke: '#000',
      strokeThickness: 4,
      backgroundColor: '#333',
      padding: { x: 8, y: 8 },
    }).setOrigin(0.5);

    this.setNicknameButton = this.createInteractiveButton(
      this.cameras.main.width / 2,
      250,
      'Set Chefs ID',
      () => {
        const input = prompt('Enter your Chef ID:');
        if (input) {
          nickname = input;
          localStorage.setItem('nickname', nickname);
          this.nicknameText.setText(`Chef ID: ${nickname}`);
        }
      }
    );

   // Leaderboard logic
   this.leaderboardButton = this.createInteractiveButton(
    this.cameras.main.width / 2,
    300,
    'Cookerboard',
    async () => {
        try {
            // Fetch leaderboard from the backend
            const response = await fetch('/api/leaderboard'); // Adjust the URL if necessary
            const leaderboard = await response.json();
  
            // Fetch total player count
            const playerCountResponse = await fetch('/api/player-count');
            const playerCountData = await playerCountResponse.json();
            const totalPlayers = playerCountData.totalPlayers ?? 0;

  
            // Sort and get the top 20 (if not already sorted by the backend)
            const top20 = leaderboard.slice(0, 20);
  
            // Create a container for the leaderboard scene
            const leaderboardContainer = this.add.container(
                this.cameras.main.width / 2,
                this.cameras.main.height / 2
            ).setDepth(10); // Ensure it appears above other elements
  
            // Create an opaque background
            const background = this.add.rectangle(
                0,
                0,
                this.cameras.main.width * 0.9, // Make the window larger
                this.cameras.main.height * 0.9, // Make the window larger
                0x333333 // Solid dark gray color
            ).setOrigin(0.5);
  
            // Title for the leaderboard with total player count
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

const title = this.add.text(
    0,
    -this.cameras.main.height * 0.4 + (isMobile ? 30 : 50),
    `Top 20 Cookers (Total Players: ${totalPlayers})`,
    {
        fontSize: isMobile ? '18px' : '40px',
        fill: '#ff6347',
        fontFamily: 'Arial',
        stroke: '#000',
        strokeThickness: isMobile ? 2 : 3,
    }
).setOrigin(0.5);

// Scale text to fit within 80% of screen width on mobile
if (isMobile) {
    const maxWidth = this.cameras.main.width * 0.8;
    if (title.width > maxWidth) {
        title.setScale(maxWidth / title.width);
    }
}
  
            // Display leaderboard entries
            const entries = top20.map(
                (entry, index) => `${index + 1}. ${entry.nickname}: ${entry.score}`
            );
            const leaderboardText = this.add.text(
                0,
                -this.cameras.main.height * 0.4 + 250, // Position the text lower
                entries.join('\n'),
                {
                    fontSize: '16px',
                    fill: '#fff',
                    fontFamily: 'Arial',
                    align: 'center',
                    wordWrap: { width: this.cameras.main.width * 0.8 },
                }
            ).setOrigin(0.5);
  
            // Close button
            const closeButton = this.createInteractiveButton(
                0,
                this.cameras.main.height * 0.4 - 70, // Adjust close button placement
                'Close',
                () => {
                    leaderboardContainer.destroy(); // Remove leaderboard container
                }
            );
  
            // Add all elements to the container
            leaderboardContainer.add([background, title, leaderboardText, closeButton]);
  
        } catch (error) {
            console.error('Failed to fetch leaderboard:', error);
  
            // Show error message to the player
            this.add.text(
                this.cameras.main.width / 2,
                this.cameras.main.height / 2,
                'Failed to load leaderboard. Please try again later.',
                {
                    fontSize: '20px',
                    fill: '#ff0000',
                    fontFamily: 'Arial',
                }
            ).setOrigin(0.5);
        }
    }
  );
  



    // Rules logic
this.rulesButton = this.createInteractiveButton(
  this.cameras.main.width / 2,
  350,
  'How to cook',
  () => {
      // Create a container for the rules window
      const rulesContainer = this.add.container(
          this.cameras.main.width / 2,
          this.cameras.main.height / 2
      ).setDepth(10); // Ensure it appears above other elements

      // Create an opaque background
      const background = this.add.rectangle(
          0,
          0,
          this.cameras.main.width * 0.9,
          this.cameras.main.height * 0.9,
          0x333333 // Solid dark gray color
      ).setOrigin(0.5);

      // Title for the rules
      const title = this.add.text(
          0,
          -this.cameras.main.height * 0.4 + 50,
          'Game Rules',
          {
              fontSize: '40px',
              fill: '#ff6347',
              fontFamily: 'Arial',
              stroke: '#000',
              strokeThickness: 3,
          }
      ).setOrigin(0.5);

      // Rules text content
      const rulesText = `
        Welcome to "Let Him Cook" - a fast-paced arcade-style game!
        - Collect marmots and heisenbergs to earn points.
        - Avoid the Best KOL Awards chef, hitting him costs you a life.
        - You only have 3 lives.

        Bonuses:
        - Chef Goggins: Restores 1 life (max 3).
        - Woody: Adds 5 points.
        - Cooked Spongebob: Instantly ends the game.
        - Diamond-handed marmots: Boost movement speed by 10% (max 50%).

        Controls:
        - Use Arrow Keys to move left and right.
      `;

      // Display rules text
      const rulesTextDisplay = this.add.text(
          0,
          -this.cameras.main.height * 0.4 + 250,
          rulesText,
          {
              fontSize: '16px',
              fill: '#fff',
              fontFamily: 'Arial',
              align: 'center',
              wordWrap: { width: this.cameras.main.width * 0.8 },
          }
      ).setOrigin(0.5);

      // Close button
      const closeButton = this.createInteractiveButton(
          0,
          this.cameras.main.height * 0.4 - 70, // Adjust close button placement
          'Close',
          () => {
              rulesContainer.destroy(); // Remove rules container
          }
      );

      // Add all elements to the container
      rulesContainer.add([background, title, rulesTextDisplay, closeButton]);
  }
);

  // Start Let Him Cook game
this.startCookGameButton = this.createInteractiveButton(
  this.cameras.main.width / 2,
  400,
  'Start Let Him Cook',
  () => {
    // Stop the background music before transitioning to the game scene
    if (this.bgMusic && this.bgMusic.isPlaying) {
      this.bgMusic.stop();
    }

    // Detect if the device is mobile
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
                     ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

    // Transition to the appropriate game scene
    this.scene.start(isMobile ? 'mobilegamescene': 'CookGameScene');
  }
);


    // Return to Site button
    this.returnToSiteButton = this.createInteractiveButton(
      this.cameras.main.width / 2,
      450,
      'Return to Site',
      () => {
        window.location.href = '/'; // Redirect to the mainpage
      }
    );
          // Add @lethimcookcoin 2025 text at the bottom-left corner
      this.add.text(10, this.cameras.main.height - 30, '@lethimcookcoin 2025', {
        fontSize: '16px',
        color: '#ffffff',
        fontFamily: 'Arial',
        stroke: '#000000',
        strokeThickness: 2,
      });

    // Add resize listener
    this.scale.on('resize', this.resize, this);

    // Force resize on window resize to fullscreen
    window.addEventListener('resize', () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.scale.resize(width, height);
    });
  }

  createInteractiveButton(x, y, text, onClick) {
    const button = this.add.text(x, y, text, {
      fontSize: '20px',
      color: '#ff6347',
      fontFamily: 'Arial',
      stroke: '#000',
      strokeThickness: 3,
      backgroundColor: '#222',
      padding: { x: 5, y: 5 },
    })
      .setOrigin(0.5)
      .setInteractive();

    // Hover effect
    button.on('pointerover', () => {
      button.setStyle({ backgroundColor: '#444', color: '#ffffff' });
    });

    // Pointer out effect
    button.on('pointerout', () => {
      button.setStyle({ backgroundColor: '#222', color: '#ff6347' });
    });

    // Click effect
    button.on('pointerdown', () => {
      button.setStyle({ backgroundColor: '#ff0000', color: '#ffffff' });
      onClick();
    });

    return button;
  }

  resize(gameSize) {
    const width = gameSize.width;
    const height = gameSize.height;
  
    // Skip resizing if the scene is not ready
    if (!this.isSceneReady) return; // Ensure the scene is fully initialized before resizing
  
    // Safely update the camera size
    if (this.cameras && this.cameras.main) {
      this.cameras.resize(width, height);
    }
  
    // Safely update the background size
    if (this.bg) {
      this.bg.setDisplaySize(width, height);
    }
  
    // Helper function to safely reposition UI elements
    const safeSetPosition = (element, x, y) => {
      if (element && typeof element.setPosition === 'function') {
        element.setPosition(x, y);
      }
    };
  
    // Helper function to safely set the size of UI elements (if applicable)
    const safeSetSize = (element, w, h) => {
      if (element && typeof element.setSize === 'function') {
        element.setSize(w, h);
      }
    };
  
    // Update positions of UI elements
    safeSetPosition(this.title, width / 2, 100);
    safeSetPosition(this.nicknameText, width / 2, 200);
    safeSetPosition(this.setNicknameButton, width / 2, 250);
    safeSetPosition(this.leaderboardButton, width / 2, 300);
    safeSetPosition(this.rulesButton, width / 2, 350);
    safeSetPosition(this.startCookGameButton, width / 2, 400);
    safeSetPosition(this.returnToSiteButton, width / 2, 450);
  
    // (Optional) If any UI elements require resizing
    safeSetSize(this.setNicknameButton, width * 0.3, 50);
    safeSetSize(this.leaderboardButton, width * 0.3, 50);
    safeSetSize(this.rulesButton, width * 0.3, 50);
    safeSetSize(this.startCookGameButton, width * 0.3, 50);
    safeSetSize(this.returnToSiteButton, width * 0.3, 50);
  }
  
  
}
