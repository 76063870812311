import React, { useState, useRef } from 'react';
import LandingPage from './LandingPage';
import Intro from './Intro';
import MainPage from './MainPage';
import GamePage from './GamePage';
import introVideo from './assets/videos/introVideo.mp4';
import backgroundVideo from './assets/videos/background.mp4';
import './App.css';

function App() {
  const [enterSite, setEnterSite] = useState(false);
  const [currentPage, setCurrentPage] = useState('main');

  const introVideoRef = useRef(null);
  const backgroundVideoRef = useRef(null);

  const handleEnter = () => {
    setEnterSite(true);
  };

  const handleSkipButtonClick = () => {
    console.log('Skip button clicked');
    setCurrentPage('main');
  };

  const handlePlayGameClick = () => {
    if (introVideoRef.current) {
      introVideoRef.current.pause();
    }
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.pause();
    }
    setCurrentPage('game');
  };

  const handleBackToMainClick = () => {
    setCurrentPage('main');
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.play();
    }
  };

  return (
    <div className="App">
      {!enterSite ? (
        <LandingPage onEnter={handleEnter} />
      ) : (
        <>
          {currentPage === 'main' && (
            <>
              <Intro
                src={introVideo}
                onButtonClick={handleSkipButtonClick}
                videoRef={introVideoRef}
              />
              <MainPage
                src={backgroundVideo}
                onPlayGameClick={handlePlayGameClick}
                videoRef={backgroundVideoRef}
              />
            </>
          )}

          {currentPage === 'game' && <GamePage onBackClick={handleBackToMainClick} />}
        </>
      )}
    </div>
  );
}

export default App;