import React, { useState } from 'react';
import Marquee from './Marquee.js';
import VideoBackground from './VideoBackground.js';
import PfpGenerator from './PfpGenerator';
import MemeGallery from './MemeGallery.js';
import './MainPage.css';

// images
const img_section1_bottomRight = require('./assets/images/marmots_base5.png');
const img_section2_bottomLeft = require('./assets/images/Woody.png');

// logos
const telegramLogo = require('./assets/logos/logo_telegram.png');
const telegramLogoHover = require('./assets/logos/logo_telegram_marmot.png');
const twitterLogo = require('./assets/logos/logo_twitter.png');
const twitterLogoHover = require('./assets/logos/logo_twitter_marmot.png');
const dexscreenerLogo = require('./assets/logos/logo_dexscreener.png');
const dexscreenerLogoHover = require('./assets/logos/logo_dexscreener_marmot.png');
const coinGeckoLogo = require('./assets/logos/logo_coinGecko.png');
const coinGeckoLogoHover = require('./assets/logos/logo_coinGecko_marmot.png');
const coinMarketCapLogo = require('./assets/logos/logo_coinMarketCap.png');
const coinMarketCapLogoHover = require('./assets/logos/logo_coinMarketCap_marmot.png');
const pumpfunLogo = require('./assets/logos/logo_pumpFun.png');
const pumpfunLogoHover = require('./assets/logos/logo_pumpFun_marmot.png');
const jupiterLogo = require('./assets/logos/logo_jupiter.png');
const jupiterLogoHover = require('./assets/logos/logo_jupiter_marmot.png');
const moonshotLogo = require('./assets/logos/logo_moonshot.png');
const moonshotLogoHover = require('./assets/logos/logo_moonshot_marmot.png');

// Main Page
const MainPage = ({ src, onPlayGameClick }) => {
    const [telegramSrc, setTelegramSrc] = useState(telegramLogo);
    const [twitterSrc, setTwitterSrc] = useState(twitterLogo);
    const [dexscreenerSrc, setDexscreenerSrc] = useState(dexscreenerLogo);
    const [coinGeckoSrc, setCoinGeckoSrc] = useState(coinGeckoLogo);
    const [coinMarketCapSrc, setCoinMarketCapSrc] = useState(coinMarketCapLogo);
    const [pumpfunSrc, setPumpfunSrc] = useState(pumpfunLogo);
    const [jupiterSrc, setJupiterSrc] = useState(jupiterLogo);
    const [moonshotSrc, setMoonshotSrc] = useState(moonshotLogo);

    return (
        <div className='main-page-container' style={{ position: 'relative' }}>
            <Marquee />
            <div className='main-page-container' style={{ position: 'relative' }}>
                <div className='main-page-contents' style={{ position: 'relative', zIndex: 500 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <img 
                    src={img_section1_bottomRight} 
                    className='img-section1-bottomRight' 
                    alt="Decorative right" 
                    style={{ 
                        cursor: 'pointer',
                        transition: 'filter 0.2s', // Smooth transition for the filter effect
                    }} 
                    onClick={onPlayGameClick} 
                    onMouseEnter={(e) => e.currentTarget.style.filter = 'brightness(150%)'} // Light up on hover
                    onMouseLeave={(e) => e.currentTarget.style.filter = 'brightness(100%)'} // Normal brightness
                    />


                    </div>
                    <h1>COOK</h1>
                    <h2>THE FAIR LAUNCH FROM PUMP.FUN TURNED CTO</h2>
                    <h3>G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n</h3>
                    <div className='main-page-buttons-container'>
                        <a href="https://t.me/LetHimCookPublicChannel" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={telegramSrc} 
                                alt="Telegram" 
                                className="logo" 
                                onMouseOver={() => setTelegramSrc(telegramLogoHover)}
                                onMouseOut={() => setTelegramSrc(telegramLogo)}
                            />
                        </a>
                        <a href="https://twitter.com/LetHimCookCoin" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={twitterSrc} 
                                alt="Twitter" 
                                className="logo" 
                                onMouseOver={() => setTwitterSrc(twitterLogoHover)}
                                onMouseOut={() => setTwitterSrc(twitterLogo)}
                            />
                        </a>
                        <a href="https://dexscreener.com/solana/g2mvmpfbxt5nhn1ky8kjf6suf7vh6y33qaquez1aykdw" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={dexscreenerSrc} 
                                alt="DexScreener" 
                                className="logo" 
                                onMouseOver={() => setDexscreenerSrc(dexscreenerLogoHover)}
                                onMouseOut={() => setDexscreenerSrc(dexscreenerLogo)}
                            />
                        </a>
                        <a href="https://www.coingecko.com/en/coins/let-him-cook" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={coinGeckoSrc} 
                                alt="coinGecko" 
                                className="logo" 
                                onMouseOver={() => setCoinGeckoSrc(coinGeckoLogoHover)}
                                onMouseOut={() => setCoinGeckoSrc(coinGeckoLogo)}
                            />
                        </a>
                        <a href="https://coinmarketcap.com/currencies/let-him-cook-art/" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={coinMarketCapSrc} 
                                alt="CoinMarketCap" 
                                className="logo" 
                                onMouseOver={() => setCoinMarketCapSrc(coinMarketCapLogoHover)}
                                onMouseOut={() => setCoinMarketCapSrc(coinMarketCapLogo)}
                            />
                        </a>
                        <a href="https://www.pump.fun/G8Vy25NzjRmuQtnN35xF7j3X2Z1TrV39XijZu8Mg4w8n" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={pumpfunSrc} 
                                alt="Pump.fun" 
                                className="logo" 
                                onMouseOver={() => setPumpfunSrc(pumpfunLogoHover)}
                                onMouseOut={() => setPumpfunSrc(pumpfunLogo)}
                            />
                        </a>
                        <a href="https://jup.ag/swap/SOL-COOK" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={jupiterSrc} 
                                alt="Jupiter" 
                                className="logo" 
                                onMouseOver={() => setJupiterSrc(jupiterLogoHover)}
                                onMouseOut={() => setJupiterSrc(jupiterLogo)}
                            />
                        </a>
                        <a href="https://moonshot.money/COOK?ref=5gfDBc7hjZ" target="_blank" rel="noopener noreferrer">
                            <img 
                                src={moonshotSrc} 
                                alt="Moonshot" 
                                className="logo" 
                                onMouseOver={() => setMoonshotSrc(moonshotLogoHover)}
                                onMouseOut={() => setMoonshotSrc(moonshotLogo)}
                            />
                        </a>
                    </div>

                    <p className='game-text' style={{ color: '#FAF9DE', fontSize: '30px', marginTop: '48px', fontWeight: '500'}}>
                            Click the marmots to try our $cook game!
                        </p>
                   
                </div>
            </div>
            <div className='main-page-contents contents-2'>
                <h1>Community Take Over</h1>
                <h2>After being released on pump.fun, a dedicated team emerged from the community and started to cook.<br/><br/> LET HIM COOK is an age old saying in the meme world, and we know <br/> WE CAN COOK </h2>
                <img src={img_section2_bottomLeft} className='img-section2-bottomLeft' alt="Decorative right" />
            </div>
            <PfpGenerator />
            <MemeGallery />
            <VideoBackground src={src} />
        </div>
    );
}

export default MainPage;